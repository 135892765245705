<template>
  <div
    class="page-content-box"
    :style="{ background: 'url(' + bgImg + ') no-repeat top center', backgroundSize: 'cover' }"
  >
    <head-main class="head-white no-fixed" :type="types"></head-main>
    <div class="custon-bread ">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>县市地图</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.provinceCityCounty }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.trueName }}</el-breadcrumb-item>
      </el-breadcrumb> -->
      <!-- <el-button type="primary" icon="el-icon-plus"  @click='diaa' size='small'>添加</el-button> -->
    </div>
    <div class="index-w">
      <index-head
        v-if="memberCertStatus == 2"
        opt-type="post"
        :text="texts"
        :active="on"
        :info="info"
        :dynamic-num="dynamicNum"
        :fan-num="fancsNum"
        :follow-num="followNum"
      ></index-head>
      <div class="inheritor-tab">
        <template>
          <el-tabs class="inheritor-eltab" v-model="activeName">
            <el-tab-pane label="我的主页" name="first">
              <div class="he-index-box person-index-box">
                <div class="he-l">
                  <div class="my-l-box">
                    <div class="person-menu">
                      <h5 class="he-tit">{{ info.trueName }}简介</h5>
                      <el-menu default-active="this.$route.path" router>
                        <el-menu-item v-if="memberCertStatus == 2" index="/myNew">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i1"></i>
                              <span slot="title">我的动态</span>
                              <!-- <span class="message-num" v-if="dynamicNum">{{ dynamicNum }}</span> -->
                            </div>
                            <div class="p-menu-r">
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item>

                        <el-menu-item index="/myConcerns">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i2"></i>
                              <span slot="title">我的关注</span>
                              <!-- <span class="message-num" v-if="followNum">{{ followNum }}</span> -->
                            </div>
                            <div class="p-menu-r">
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item>
                        <el-menu-item v-if="memberCertStatus == 2" index="/myFans">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i3"></i>
                              <span slot="title">我的粉丝</span>
                              <!-- <span class="message-num" v-if="fancsNum">{{ fancsNum }}</span> -->
                            </div>
                            <div class="p-menu-r">
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item>
                        <el-menu-item index="/messageTwo">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i4"></i>
                              <span slot="title">消息通知</span>
                              <span class="message-num" v-if="totalNewChatNum">{{ totalNewChatNum }}</span>
                            </div>
                            <div class="p-menu-r">
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item>
                        <!-- <el-menu-item index="/message">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i4"></i>
                              <span slot="title">消息通知</span>
                            </div>
                            <div class="p-menu-r">
                              <span>5</span>
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item> -->
                        <el-menu-item index="/myinformation">
                          <div class="p-menu-box">
                            <div class="p-menu-l">
                              <i class="per-i5"></i>
                              <span slot="title">编辑资料</span>
                            </div>
                            <div class="p-menu-r">
                              <i class="menu-tip"></i>
                            </div>
                          </div>
                        </el-menu-item>
                      </el-menu>
                    </div>
                    <div v-if="memberCertStatus == 2" class="per-content">
                      <div class="he-conten" :class="is_show ? 'work_detail' : 'new_detail'">
                        <h5 class="he-tit">{{ info.trueName }}简介</h5>
                        <ul class="content-list">
                          <li>非遗名录：{{ info.fintangibleDir }}</li>
                          <li>非遗类型：{{ info.fintangibleType }}</li>
                          <!-- <li>传承人级别：{{ info.inheritorLevel }}</li> -->
                          <li>出生日期：{{ info.birthday }}</li>
                          <li>地区：{{ info.provinceCityCounty }}</li>
                          <li>性别：{{ info.sex }}</li>
                        </ul>
                        <!-- <img :src="artical1" /> -->
                        <p v-html="info.webContent"></p>
                      </div>
                      <div class="btn-box">
                        <el-button class="more-btn" @click="word">{{ show_t }}</el-button>
                      </div>
                    </div>
                  </div>
                </div>

                <router-view
                  :head-icon="info.webImg"
                  :user-info="userInfo"
                  :key="$route.name"
                  :follow-num.sync="followNum"
                ></router-view>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane v-if="memberCertStatus == 2" label="我的相册" name="second">
              <div class="he-bg-white">
                <template>
                  <el-tabs class="el-tabs-other" v-model="activeNames">
                    <el-tab-pane label="图片和视频" name="first">
                      <ul class="photo-list">
                        <template v-for="time in Object.keys(photoList)">
                          <li v-if="time.length > 0" :key="time">
                            <h5 class="photo-time">{{ time }}</h5>
                            <ul class="photo-img-list">
                              <template v-for="item in photoList[time]">
                                <template v-if="item.blogType === 1">
                                  <template v-for="(img, index) in item.bolgImgs.split(',')">
                                    <li
                                      :key="item.id + index"
                                      :style="{
                                        background: 'url(' + img + ') no-repeat center center',
                                        backgroundSize: 'cover'
                                      }"
                                    >
                                      <div class="photo-fu">
                                        <span
                                          >点赞<i>{{ photo.num }}</i></span
                                        >
                                        <span
                                          >浏览<i>{{ photo.view }}</i></span
                                        >
                                      </div>
                                    </li>
                                  </template>
                                </template>
                                <template v-if="item.blogType === 2">
                                  <li :key="item.id" class="video-wrap">
                                    <video :src="item.blogVideo" controls preload="metadata" />
                                  </li>
                                </template>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </template>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>

    <pop-inheritor :shows="showss" :width="width" :height="height" @closepop="closepop" :close="close"></pop-inheritor>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import IndexHead from '../../components/indexhead'
import PopInheritor from '../index/popInheritor'

export default {
  components: {
    HeadMain,
    BottoCom,
    IndexHead,
    PopInheritor
  },

  data() {
    let memberCertStatus = localStorage.getItem('memberCertStatus')
    return {
      memberCertStatus,
      info: {},
      userInfo: {},
      fancsNum: 0,
      totalNewChatNum: 0,
      followNum: 0,
      dynamicNum: 0,
      texts: '发动态',
      on: 'on',
      types: 'false',
      show_t: '查看全部',
      is_show: 'work_detail',
      showss: false,
      width: '1200px',
      height: '600px',
      activeName: 'first',
      activeNames: 'first',
      close: true,
      isOn: true,
      bgImg: require('../../assets/images/bg5.jpg'),
      artical1: require('../../assets/images/article-img1.png'),
      artical2: require('../../assets/images/article-img2.png'),

      headicon: require('../../assets/images/people.png'),
      level: require('../../assets/images/level.png'),
      photoList: [
        // {
        //   time: '2020年09月',
        //   phpto: [
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     }
        //   ]
        // },
        // {
        //   time: '2020年09月',
        //   phpto: [
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png'),
        //       num: '254',
        //       view: '222'
        //     }
        //   ]
        // }
      ],
      vidoeList: []
    }
  },
  methods: {
    diaa() {
      // this.showss = true
      //   this.showss== !this.showss;
    },
    closepop() {
      this.showss = false
    },
    word() {
      this.is_show = !this.is_show
      if (this.is_show === false) {
        this.show_t = '收起文章'
        // return 'show_t'
      } else if (this.is_show === true) {
        this.show_t = '查看全部'
        // return '收起'
      } else if (this.is_show === '') {
        return null
      }
    },
    ison() {
      this.isOn = !this.isOn
    },
    // get data
    getPhotoList() {
      this.axios.get('/api/portal/blog/auth/list-my-materials', {}).then(res => {
        this.photoList = res.data
      })
    },
    getInheritorData() {
      if (this.memberCertStatus != 2) return
      this.axios.get('/api/portal/cont/my-inheritor', {}).then(res => {
        let data = res.data
        let inheritorData = data.inheritorData
        this.info = {
          webImg: inheritorData.webImg,
          trueName: inheritorData.trueName,
          fintangibleDir: inheritorData.fintangibleDir,
          fintangibleType: inheritorData.fintangibleType,
          inheritorLevel: inheritorData.inheritorLevel,
          birthday: inheritorData.birthday ? this.$dayjs(inheritorData.birthday).format('YYYY-MM') : '',
          provinceCityCounty: inheritorData.provinceCityCounty,
          sex: inheritorData.sex,
          webContent: inheritorData.webContent
        }
      })
    },
    getUserInfo() {
      this.axios.get('/api/portal/member/current').then(res => {
        let data = res.data
        let userInfo = data.member
        this.fancsNum = data.fancsNum
        this.followNum = data.followNum
        this.dynamicNum = res.data.blogNum
        this.totalNewChatNum = data.totalNewChatNum
        this.userInfo = {
          nickName: userInfo.nickName,
          sex: userInfo.sex,
          birthday: userInfo.birthday ? this.$dayjs(userInfo.birthday).format('YYYY-MM') : '',
          phone: userInfo.phone,
          email: userInfo.email,
          slogan: userInfo.slogan,
          createTime: this.$dayjs(userInfo.createTime).format('YYYY年MM月DD日')
        }
      })
    }
  },
  mounted() {
    this.getInheritorData()
    this.getUserInfo()
    this.getPhotoList()
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  },
  computed: {
    ...mapState(['postUpdated'])
  },
  watch: {
    postUpdated(postUpdated) {
      if (!postUpdated) {
        this.dynamicNum += 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.inheritor-eltab /deep/ .el-tabs__content {
  min-height: calc(100vh - 375px);
}
</style>
